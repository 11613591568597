import React from 'react';
import Grid from '@mui/material/Grid';
import { Card, CardContent, Typography } from "@mui/material";
import { Bar } from "react-chartjs-2";


const monthNames = ["", "Jan", "Feb", "March", "April", "May", "June",
    "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

export default function CustomCard({ domainSales, earnings }) {
    const domainLabels = [];
    const domainValues = [];
    for (let i = 0; i < domainSales.length; i++) {
        domainLabels.push(monthNames[domainSales[i]._id.month] + " " + domainSales[i]._id.year);
        domainValues.push(domainSales[i].domains)
    }

    const earningsLabels = [];
    const earningsValues = [];
    for (let i = 0; i < earnings.length; i++) {
        earningsLabels.push(monthNames[earnings[i]._id.month] + " " + earnings[i]._id.year);
        earningsValues.push(earnings[i].earning)
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant='h6' style={{ fontWeight: 700 }}>Analytics</Typography>
            </Grid>
            <Grid item xs={6} md={6} sm={12}>
                <Card >
                    <CardContent style={{ textAlign: "center" }}>
                        <Typography p={1} sx={{ fontSize: 14 }} style={{ textAlign: "left", fontWeight: "bold" }}>
                            Domain Sales Stats
                        </Typography>
                        <Bar
                            options={{ scales: { x: { grid: { display: false } }, y: { grid: { display: false } } } }}
                            data={{
                                labels: domainLabels, datasets: [{
                                    data: domainValues, backgroundColor: "#35A5F5",
                                    barThickness: 20
                                }]
                            }}
                            style={{ maxWidth: "600px", maxHeight: "300px" }}
                        />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6} md={6} sm={12}>
                <Card >
                    <CardContent style={{ textAlign: "center" }}>
                        <Typography p={1} sx={{ fontSize: 14 }} style={{ textAlign: "left", fontWeight: "bold" }}>
                            Total Revenue Stats (QIE)
                        </Typography>
                        <Bar
                            options={{ scales: { x: { grid: { display: false } }, y: { grid: { display: false } } } }}
                            data={{
                                labels: earningsLabels, datasets: [{
                                    data: earningsValues, backgroundColor: "#35A5F5",
                                    barThickness: 20
                                }]
                            }}
                            style={{ maxWidth: "600px", maxHeight: "300px" }}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
