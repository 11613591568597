import React from 'react';
import Grid from '@mui/material/Grid';
import { Card, CardContent, Paper, TableContainer, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

const monthNames = ["", "Jan", "Feb", "March", "April", "May", "June",
    "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

const columns: GridColDef[] = [
    { field: 'id', headerName: 'Sr No.', width: 70 },
    { field: 'month', headerName: 'Month', width: 150 },
    { field: 'year', headerName: 'Year', width: 150 },
    {
        field: 'qie',
        headerName: 'Total Earnings (QIE)',
        width: 200,
    },
    {
        field: 'transactionId',
        headerName: 'Transaction Id',
        width: 300,
    },
    {
        field: 'createdDate',
        headerName: 'Transaction Date',
        sortable: false,
        width: 300,
    },
    {
        field: 'action',
        headerName: 'Action',
        sortable: false,
        width: 200,
    }

];

const rows = [
    { id: 1, month: 'July', year: '#2022', qie: '100', transactionId: '1213', createdDate: '10/10/2022', action: '-' },
    { id: 2, month: 'July', year: '#2022', qie: '100', transactionId: '1213', createdDate: '10/10/2022', action: '-' },
    { id: 3, month: 'July', year: '#2022', qie: '100', transactionId: '1213', createdDate: '10/10/2022', action: '-' },
    { id: 4, month: 'July', year: '#2022', qie: '100', transactionId: '1213', createdDate: '10/10/2022', action: '-' },
    { id: 5, month: 'July', year: '#2022', qie: '100', transactionId: '1213', createdDate: '10/10/2022', action: '-' },
];

export default function CustomCard({ transactions }) {
    const data = transactions.map((item, index) => {
        let date = new Date(item.createdAt)
        return {
            id: index,
            month: monthNames[date.getMonth()],
            year: date.getFullYear(),
            qie: item.amount,
            transactionId: item.transactionId,
            createdDate: date.toDateString()
        }
    })
    return (
        <Grid item xs={12}>
            <Typography variant='h6' style={{ fontWeight: 700 }}>Transaction Details</Typography>
            <TableContainer component={Paper}>
                <DataGrid
                    rows={data}
                    columns={
                        columns
                    }
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                    disableRowSelectionOnClick
                />
            </TableContainer>
        </Grid>
    );
}
