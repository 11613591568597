import { ethers } from 'ethers';
// import Web3 from 'web3';
import detectEthereumProvider from '@metamask/detect-provider';
import paymentContractw from './PaymentGateway_.json';
import paymentContract from './PaymentGateway.json';

const Web3 = {};
const { formatEther, parseEther } = require("@ethersproject/units");

const CONTRACT_ADDRESS = "0xda2e5107d4457363F3eC23D69Fca1E9011B68fDD";
const ethereum = window.ethereum;

const getWeb3Provider = async () => {
    const provider = await detectEthereumProvider();

    return new ethers.providers.Web3Provider(provider);
};

const getWeb3Account = async provider => {
    try {
        const accounts = await provider.send('eth_requestAccounts', []);
        return accounts[0];
    } catch (error) {
        console.error('getWeb3Account', error?.message);
        return null;
    }
};

const checkChainId = async chainId => {
    if ((await ethereum.request({ method: 'eth_chainId' })) !== chainId) {
        try {
            return await ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId }],
            });
        } catch (switchError) {
            if (switchError.code === 4902) {
                console.error('This network is not available in your metamask.');
            }
            console.error('Failed to switch to the network');
            return false;
        }
    }
};

export const getQiNetworkDetails = () => {
    const qiNetwork = 'mainnet';

    if (qiNetwork === 'mainnet') {
        return {
            chainId: '0x1618',
            chainName: 'QI Blockchain - Mainnet',
            nativeCurrency: { name: 'QIE', symbol: 'QIE', decimals: 18 },
            rpcUrls: ['https://rpc-main1.qiblockchain.online'],
            blockExplorerUrls: ['https://mainnet.qiblockchain.online'],
        };
    } else {
        return {
            chainId: '0x1618',
            chainName: 'QI Blockchain - Testnet',
            nativeCurrency: { name: 'QIE', symbol: 'QIE', decimals: 18 },
            rpcUrls: ['https://rpc-main1.qiblockchain.online'],
            blockExplorerUrls: ['https://testnet.qiblockchain.online'],
        };
    }
};

export const connectWallet = async () => {
    const provider = await getWeb3Provider();
    if (!provider) {
        throw new Error('missing provider');
    }
    const account = await getWeb3Account(provider);

    if (account) {
        return account;
    } else {
        throw Error();
    }
}

export const getBalance = async () => {

    const provider = await getWeb3Provider();
    const signer = provider.getSigner();

    const account = await signer.getAddress();
    const networkDetails = getQiNetworkDetails();

    const chainId = networkDetails?.chainId;

    await checkChainId(chainId);

    const PgatewayInstance = new ethers.Contract(
        CONTRACT_ADDRESS,
        paymentContract.abi,
        signer,
    );
    let tx = await PgatewayInstance.knowBalance();
    return formatEther(parseInt(tx._hex, 16) + '')//1000000000000000000;
}

export const getBalanceWeb3 = async () => {

    const provider = await getWeb3Provider();
    const signer = provider.getSigner();

    const account = await signer.getAddress();
    console.log(account)
    const networkDetails = getQiNetworkDetails();

    const chainId = networkDetails?.chainId;

    await checkChainId(chainId);

    const web3Instance = new Web3(window.ethereum)
    console.log(web3Instance)
    console.log(paymentContract.abi)
    const PgatewayInstance = new web3Instance.eth.Contract(
        paymentContractw.abi,
        CONTRACT_ADDRESS
    );
    console.log(PgatewayInstance.methods)

    const reg = await PgatewayInstance.methods.knowBalance().call({ gas: 3200000, from: account });
    console.log("getBalanceWeb3", reg);

}

export const redeemBalance = async () => {

    const provider = await getWeb3Provider();
    const signer = provider.getSigner();

    const account = await signer.getAddress();
    const networkDetails = getQiNetworkDetails();

    const chainId = networkDetails?.chainId;

    await checkChainId(chainId);

    const PgatewayInstance = new ethers.Contract(
        CONTRACT_ADDRESS,
        paymentContract.abi,
        signer,
    );
    let tx = await PgatewayInstance.withdraw();
    console.log(tx);
    return tx;
}

export const redeemBalanceWeb3 = async () => {

    const provider = await getWeb3Provider();
    const signer = provider.getSigner();

    const account = await signer.getAddress();
    const networkDetails = getQiNetworkDetails();

    const chainId = networkDetails?.chainId;

    await checkChainId(chainId);

    const web3Instance = new Web3(window.ethereum)

    const PgatewayInstance = new web3Instance.eth.Contract(
        paymentContract.abi,
        CONTRACT_ADDRESS
    );

    const reg = await PgatewayInstance.methods.withdraw().send({ gas: 3200000, from: account });
    console.log("getBalanceWeb3", reg);
    return reg;
}