import React, { useState, useEffect } from "react";
import {
    Box, Paper, Grid, IconButton, InputBase, Button,
    Stack, Snackbar, Alert,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import axios from 'axios';
import CustomCard from "./components/card";
import CustomTable from "./components/table";
import CustomChart from "./components/chart";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { DataGrid } from '@mui/x-data-grid';

import { connectWallet, getBalance, redeemBalance, getBalanceWeb3, redeemBalanceWeb3 } from './lib/qie';

import Login from "./Login";
import logo from './hovr.png';

import './App.css';

const BASE_URL = "https://domains.hovr.site";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
);
function App() {
    const [login, setLogin] = useState(localStorage.getItem("adminToken"));
    const [loginError, setLoginError] = useState(null);
    const [figures, setFigures] = useState({});
    const [walletAccount, setWalletAccount] = useState(null);
    const [startRedeem, setStartRedeem] = useState(false);
    const [redeemProgress, setRedeemProgress] = useState(false);
    const [avaialbleBalance, setAvaialbleBalance] = useState(null);
    const [alert, setAlert] = useState({});
    const data = {};
    data.title = 'Total Domain Sold';
    data.value = '200';
    data.rate = '20%';
    data.range = 'vs previous 30 days';

    useEffect(() => {
        if (login) {
            axios.get(BASE_URL + '/api/admindashboard', {
                headers: {
                    auth: login
                }
            })
                .then(function (response) {
                    // handle success
                    // console.log(response);
                    setFigures(response.data)
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
                .finally(function () {
                    // always executed
                });
            getWalletBalance();
        }
    }, [login]);

    const refreshAll = async () => {
        axios.get(BASE_URL + '/api/admindashboard', {
            headers: {
                auth: login
            }
        })
            .then(function (response) {
                // handle success
                // console.log(response);
                setFigures(response.data)
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
        getWalletBalance();
    }

    const getWalletBalance = async () => {
        console.log("Getting Balance")
        try {
            const balance = await getBalance();
            setAvaialbleBalance(balance);
        } catch (e) {
            console.log(e)
        }
    }

    const connectToWallet = async () => {
        try {
            const account = await connectWallet();
            setWalletAccount(account);
            // getWalletBalance();
        } catch (e) {
            console.log(e)
        }
    }

    const loginWithWallet = async (password) => {
        try {
            console.log("loginWithWallet", password)
            if (password.length === 0)
                setAlert({ open: true, level: "info", message: "Password is required!" })
            else {
                const account = await connectWallet();
                setWalletAccount(account);
                loginAction({ password, account });
            }
        } catch (e) {
            console.log(e)
            setAlert({ open: true, level: "error", message: "Unable to connect to wallet!" })

        }
    }

    const loginAction = ({ email, password, account }) => {
        axios.post(BASE_URL + '/api/admindashboard/login', { email, password, account })
            .then(function (response) {
                // handle success
                console.log(response);
                localStorage.setItem("adminToken", response.data.jwt);
                setLogin(response.data.jwt)
            })
            .catch(function (error) {
                // handle error
                setLoginError(error.response.data.error);
            });
    }

    const onRedeemClick = async () => {
        setRedeemProgress(true);
        try {
            let tx = await redeemBalance();
            if (tx.hash) {
                setStartRedeem(false);
                setAlert({ open: true, level: "success", message: "Successfully Redeemed!" })
                axios.post(BASE_URL + '/api/admindashboard/recordtransaction', { transactionId: tx.hash, amount: avaialbleBalance })
                    .then(function (response) {
                        refreshAll();
                    })
                    .catch(function (error) {
                        console.log(error)
                    });
            } else {
                setAlert({ open: true, level: "error", message: "Unable to proceed, please try again latter!" })
            }
            setRedeemProgress(false);
        } catch (e) {
            console.log(e)
            setRedeemProgress(false);
        }
    }

    const closeAlert = () => {
        setAlert({})
    }

    if (!login || !walletAccount)
        return (<div>
            <Snackbar open={alert.open} autoHideDuration={6000} onClose={closeAlert}>
                <Alert onClose={closeAlert} severity={alert.level} sx={{ width: '100%' }}>
                    {alert.message}
                </Alert>
            </Snackbar>
            <Login loginAction={loginAction} loginError={loginError} loginWithWallet={loginWithWallet} />
        </div>)
    else
        return (
            <div className="App">
                <Snackbar open={alert.open} autoHideDuration={6000} onClose={closeAlert}>
                    <Alert onClose={closeAlert} severity={alert.level} sx={{ width: '100%' }}>
                        {alert.message}
                    </Alert>
                </Snackbar>
                <Dialog
                    open={startRedeem}
                    onClose={() => {
                        setStartRedeem(false)
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Do you want to proceed?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            By proceeding you agree to transfer available balance to your wallet.
                        </DialogContentText>
                        {redeemProgress && <Box sx={{ marginLeft: "44%" }}>
                            <CircularProgress />
                        </Box>}
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={redeemProgress} onClick={() => {
                            setStartRedeem(false)
                        }}>Latter</Button>
                        <Button disabled={redeemProgress} onClick={onRedeemClick} autoFocus>
                            Proceed
                        </Button>
                    </DialogActions>
                </Dialog>
                <Stack spacing={2}>
                    <Grid container>
                        <Grid item xs={2}>
                            <img src={logo} alt='logo' />
                        </Grid>
                        <Grid item xs={6}>
                            <Paper
                                component="form"
                                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                            >
                                <IconButton sx={{ p: '10px' }} aria-label="menu">
                                    <SearchIcon />
                                </IconButton>
                            </Paper>
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: "end" }}>
                            <Stack direction="row" spacing={2}
                                justifyContent="flex-end">
                                <Button variant="outlined" onClick={refreshAll}>Refresh<RefreshIcon /></Button>
                                {/* <Button variant={walletAccount ? "outlined" : "contained"} onClick={connectToWallet}>{walletAccount ? (walletAccount.slice(0, 8) + '...' + walletAccount.slice(-4)) : "Connect Wallet"}</Button> */}
                                <Button color="warning" variant="contained" onClick={() => {
                                    localStorage.clear();
                                    setLogin(null);
                                }}>Logout</Button>
                            </Stack>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={3} sm={6} md={3}>
                            <CustomCard data={{
                                title: "Total Domain Sold",
                                value: figures.totalDomainSold || "--",
                                rate: "20",
                                range: "vs previous 30 days"
                            }} />
                        </Grid>
                        <Grid item xs={3} sm={6} md={3}>
                            <CustomCard data={{
                                title: "Total Earning",
                                value: "QIE " + (figures.totalEarning || "--"),
                                rate: "20",
                                range: "vs previous 30 days"
                            }} />
                        </Grid>
                        <Grid item xs={3} sm={6} md={3}>
                            <CustomCard data={{
                                title: "Total User",
                                value: figures.totalUsers || "--",
                                rate: "20",
                                range: "vs previous 30 days"
                            }} />
                        </Grid>
                        <Grid item xs={3} sm={6} md={3}>
                            <CustomCard data={{
                                title: "Payment & Redeem",
                                value: avaialbleBalance || "--",
                            }} redeem={() => {
                                setStartRedeem(true)
                            }}
                                walletAccount={walletAccount} />
                        </Grid>
                    </Grid>

                    <CustomChart domainSales={figures.domainSales || []} earnings={figures.earnings || []} />
                    <Grid container spacing={2}>
                        <CustomTable transactions={figures.transactions || []} />
                    </Grid>
                </Stack>
            </div>
        );
}

export default App;