import React from 'react';
import { Card, CardContent, Typography, Tooltip as MUITooltip, Button } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

export default function CustomCard({ data, redeem, walletAccount }) {

    return (
        <Card sx={{ minWidth: 320 }}>
            <CardContent style={{ textAlign: "center" }}>
                <Typography sx={{ fontSize: 14 }} style={{ textAlign: "left", fontWeight: "bold" }}>
                    {data.title}
                </Typography>
                <Typography variant='h4' component="div" style={{ fontWeight: "700" }}>
                    {data.value}
                </Typography>
                {data.rate && <Typography sx={{ mb: 1.5 }} style={{ color: "#75B569", fontSize: "13px" }}>
                    <ArrowUpwardIcon style={{ fontSize: "12px" }} /> {data.rate}
                </Typography>}
                {data.range && <Typography variant="p" style={{ fontSize: "13px", fontWeight: "500" }}>
                    {data.range}
                </Typography>}
                {redeem && <Typography sx={{ mb: 1.2 }} style={{ color: "#75B569", fontSize: "14px" }}>
                    QIE
                </Typography>}
                {redeem && <MUITooltip title={walletAccount ? "Click to redeem" : "Please Connect your wallet!"} arrow>
                    <span>
                        <Button disabled={!walletAccount} variant="text"
                            style={{ fontSize: "13px", padding: "0px 10px 0px 10px" }}
                            onClick={redeem}>Redeem Now</Button>
                    </span>
                </MUITooltip>}
            </CardContent>
        </Card>
    );
}
