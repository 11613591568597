import { TextField, Button, Stack, Card, CardContent, Typography } from '@mui/material';
import logo from './hovr.png';

function Login({ loginAction, loginError, loginWithWallet }) {
    const onLogin = () => {
        const email = document.getElementById("email").value;
        const password = document.getElementById("password").value;
        const loginData = { email, password };
        loginAction(loginData);
    }

    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ height: "95vh" }}
        >
            <Card sx={{ minWidth: 320, padding: "40px" }}>
                <CardContent>
                    <Stack style={{ textAlign: "center" }} spacing={2}>
                        <img src={logo} width={"100px"} style={{ margin: "auto" }} />
                        <br />
                        {/* <TextField
                            label="Email"
                            id="email"
    /> */}
                        <TextField
                            label="Password"
                            type='password'
                            id="password"
                        />
                        {/* <Button variant="contained" onClick={onLogin}>Login</Button> */}
                        <Button variant={"contained"} onClick={() => loginWithWallet(document.getElementById("password").value)}>Login with Wallet</Button>

                        {
                            loginError && <Typography color={"error"} style={{ textAlign: "left", marginTop: "3px" }}>{loginError}</Typography>
                        }
                    </Stack>
                </CardContent>
            </Card>
        </Stack>
    )
}

export default Login;